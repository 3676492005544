(function ($, Drupal, document, site, Mustache) {
  Drupal.behaviors.customCollectionProductTrayV1 = {
    data: {},
    collection: {},
    dimensions: {},
    nodes: {},
    mustache: {},
    defaults: {
      disableClass: 'disable',
      maxThumbsPerRow: 6,
      maxTotalTumbs: 8,
      open: {
        duration: 1000,
        onComplete: function () {}
      },
      close: {
        duration: 1000,
        onComplete: function () {}
      }
    },

    attach: function (context) {
      this.context = context;

      this.getTrayData();
      this.getNodes();
      this.getDimensions();
      this.openTrayEvents();
      this.setEvents();
    },

    setEvents: function () {
      var self = this;

      $(document).on('customCollection:addToGift:success', $.proxy(this.onAddToGiftSuccess, this));
      $(document).on('customCollection:removeFromGift:success', $.proxy(this.onRemoveFromGiftSuccess, this));
      $(document).on('customCollection:removeFromGift:failure', $.proxy(this.onRemoveFromGiftFailure, this));
      $(document).on('customCollection:trayClose', $.proxy(this.closeTrayEvents, this));
      $(document).on('customCollection:trayOpen', $.proxy(this.openTrayEvents, this));

      if (this.nodes.$stickyFooter) {
        this.setTrayStickyState();

        $(document).on('scroll', _.throttle(function () {
          self.setTrayStickyState();
        }, 100));
      }
    },

    setTrayStickyState: function () {
      var offset = this.nodes.$tray.offset() ? this.nodes.$tray.offset().top - $(window).height() + (this.dimensions.$stickyFooterHeight + this.dimensions.$trayHeight) : 0;

      this.nodes.$tray.toggleClass('custom-collection-tray--sticky', $(window).scrollTop() < offset);
    },

    getNodes: function () {
      this.nodes.$tray = $('.js-custom-collections-product-tray-v1', this.context);
      this.nodes.$trayWrapper = $('.js-custom-collection-tray-wrapper', this.nodes.$tray);
      this.nodes.$maxErrorBanner = $('.js-max-error-banner', this.context);
      this.nodes.$maxErrorMessage = $('.js-max-error-message', this.context);
      this.nodes.$stickyFooter = $('.page-sticky-footer', this.context);
    },

    getDimensions: function () {
      this.dimensions.$trayHeight = this.nodes.$tray.height();
      this.dimensions.$stickyFooterHeight = this.nodes.$stickyFooter.height();
    },

    onAddToGiftSuccess: function (event, args) {
      $(document).trigger('customCollection:trayOpen', args || {});
    },

    onRemoveFromGiftFailure: function (event, args) {
      this.onRemoveFromGiftSuccess(event, args);
    },

    onRemoveFromGiftSuccess: function () {
      var self = this;

      this.removeMaxErrors();

      if (this.nodes.$tray.length) {
        $(document).trigger('customCollection:getCollectionItems', {
          onSuccess: function (response) {
            self.collection = response;

            if (self.collection.total_items <= 0) {
              self.closeTray();

              $(document).trigger('customCollection:clearCollection');

              return null;
            }

            self.renderTray({
              callback: function () {
                self.setThumbDeleteBtns();
              }
            });
          }
        });
      }
    },

    removeMaxErrors: function () {
      this.nodes.$maxErrorBanner.addClass('hidden');

      this.nodes.$maxErrorMessage.addClass('hidden');
    },

    renderTray: function (args) {
      var collectionInfo = this.collection;

      if (!args || !collectionInfo) {
        return;
      }

      collectionInfo.totalCopyPlural = this.nodes.$tray.data('total-text-plural'); // should always say 1/6 items, etc. (plural)

      collectionInfo.removeButtonText = this.nodes.$tray.data('remove-button-text');

      collectionInfo.giftLinkUrl = this.nodes.$tray.data('gift-link-url');

      collectionInfo.giftLinkTitle = this.nodes.$tray.data('gift-link-title');

      collectionInfo = $.extend(collectionInfo, this.data);

      var renderedInfo = site.template.get({
        name: 'custom_collection_product_tray_info',
        data: collectionInfo
      });
      var output = Mustache.render(renderedInfo, collectionInfo);

      this.nodes.$trayWrapper.html(output);
      this.nodes.$trayWrapper.find('.custom-collection-tray-info.hidden').removeClass('hidden');

      this.renderCarousel({
        collection: collectionInfo
      });

      setTimeout(function () {
        this.setTrayCarousel();
      }.bind(this));

      this.setMaxProducts();

      if (args.callback) {
        args.callback();
      }
    },

    openTrayEvents: function (event, args) {
      var self = this;

      args = args || {};

      if (this.nodes.$tray.length > 0) {
        $(document).trigger('customCollection:getCollectionItems', {
          onSuccess: function (response) {
            self.collection = response;

            if (self.collection && self.collection.skus && self.collection.skus.length > 0) {
              self.renderTray({
                callback: function () {
                  self.setThumbDeleteBtns();

                  self.openTray({
                    params: args.params
                  });
                }
              });
            }
          }
        });
      }
    },

    closeTrayEvents: function (event, args) {
      this.closeTray(args);
    },

    openTray: function (args) {
      $('body').addClass('custom-collection-landing--tray-open js-custom-collection-landing--tray-open');

      this.openNode(
        $.extend(args || {}, {
          $node: this.nodes.$trayWrapper
        })
      );
    },

    closeTray: function (args) {
      args = args || {};

      var modArgs = $.extend(args, {
        $node: this.nodes.$trayWrapper
      });

      this.closeNode(modArgs);

      $('body').removeClass('custom-collection-landing--tray-open js-custom-collection-landing--tray-open');
    },

    openNode: function (args) {
      args = args || {};

      if (!args.$node) {
        return;
      }

      var openParams = $.extend(this.defaults, args.params);
      var completeCallback = args.onComplete || openParams.open.onComplete;

      args.$node.slideDown(openParams.open.duration, function () {
        if (completeCallback) {
          completeCallback();
        }
      });
    },

    closeNode: function (args) {
      args = args || {};

      if (!args.$node) {
        return null;
      }

      var closeParams = args.params ? args.params : this.defaults;
      var completeCallback = args.onComplete || closeParams.close.onComplete;

      args.$node.slideUp(closeParams.close.duration, completeCallback);
    },

    setThumbDeleteBtns: function () {
      $('.js-product-delete', this.context).on('click', function (event) {
        event.preventDefault();

        $(document).trigger('customCollection:removeFromGift', {
          skuId: $(this).data('sku-id'),
          skuQty: $(this).data('sku-qty')
        });
      });
    },

    isCarousel: function (args) {
      return !site.client.isMobile || args.collection;
    },

    renderCarousel: function (args) {
      if (!this.isCarousel) {
        return;
      }

      var collection = args.collection;
      var $thumbsContainer = $('.js-gift-thumbs', this.nodes.$trayWrapper);
      var qtys = collection.qtys;
      var i = 0;
      var j = 0;
      var k = 0;
      var skusCollectionLength = collection.skus.length;
      var skusToRenderLength = 0;
      var skus_to_render = [];
      var thumbs = [];
      var sku;

      // Skus with qty > 1 should be displayed once for each of their qty
      for (i = 0; i < skusCollectionLength; i++) {
        sku = collection.skus[i];

        if (qtys[sku.SKU_BASE_ID] > 1) {
          for (j = 0; j < qtys[sku.SKU_BASE_ID]; j++) {
            skus_to_render.push(sku);
          }
        } else {
          skus_to_render.push(sku);
        }
      }

      skusToRenderLength = skus_to_render.length;

      for (k = 0; k < skusToRenderLength; k++) {
        sku = skus_to_render[k];
        sku.SKU_QTY = qtys[sku.SKU_BASE_ID];
        sku.removeButtonText = collection.removeButtonText;

        sku.SMALL_IMAGE = this.getThumbImage(sku);
        var renderedItem = site.template.get({
          name: 'custom_collection_product_tray_item',
          data: sku
        });

        thumbs.push(Mustache.render(renderedItem, sku));
      }

      $thumbsContainer.append(thumbs);
    },

    getThumbImage: function (sku) {
      var image;

      if (sku.IMAGE_M) {
        image = sku.IMAGE_M;
      } else if (sku.MEDIUM_IMAGE) {
        image = sku.MEDIUM_IMAGE;
      } else if (sku.product.IMAGE_M) {
        image = sku.product.IMAGE_M;
      } else if (sku.product.IMAGE_L) {
        image = sku.product.IMAGE_L;
      } else {
        return;
      }

      return image[0] || image;
    },

    getTrayData: function () {
      if (!site || !site.tray) {
        return;
      }

      this.data = site.tray;
    },

    setTrayCarousel: function () {
      $('.js-gift-thumbs', this.context).slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToScroll: 1,
        slidesToShow: 5,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1420,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1125,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    },

    setMaxProducts: function () {
      if (!_.isUndefined(site.templates.custom_collections_product_tray)) {
        $('.js-custom-collection-tray-info-total-items', this.context).html('/' + site.templates.custom_collections_product_tray.data.gift_items_max_allowed);
      }
    }
  };
})(jQuery, Drupal, document, window.site || {}, window.Mustache || {});
